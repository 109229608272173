/* eslint-disable @next/next/next-script-for-ga */
/** @format */
import { Col, ConfigProvider, Layout, Row } from "antd";
import "antd/dist/reset.css";
import { appWithTranslation } from "next-i18next";
import { type AppType } from "next/dist/shared/lib/utils";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import { getFromURL } from "../components/utils";
import CustomLiveChat from "../components/widgets/CustomLiveChat";
import { AppWrapper } from "../context/CommonContext";
import "../styles/globals.css";
const MyApp: AppType = ({ Component, pageProps }) => {
	const [loading, setLoading] = useState(true);
	// const [thanku, setthanku] = useState("");
	const router = useRouter();
	const hideNavbarFooter =
		router.pathname === "/financial-information-statement-form" || router.pathname === "/financial-information-statement-form-b";
	useEffect(() => {
		setLoading(false);
	}, []);
	useEffect(() => {
		// Assuming router is imported and contains the current route information
		const baseUrl = "https://example.com"; // Replace with your actual base URL
		const url = new URL(baseUrl + router.pathname);
		// Call the getFromURL function and store the result directly in localStorage
		localStorage.setItem("router", getFromURL({ url }).toString());
	}, [router]);
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	return (
		<>
			<div style={{ opacity: loading ? 0 : 1 }}>
				<AppWrapper>
					<ConfigProvider
						theme={{
							token: {
								colorPrimary: "#254486",
								borderRadius: 4,
								fontWeightStrong: 400,
								colorBgLayout: "#fff",
								fontFamily: "'Raleway', sans-serif",
							},
						}}>
						<Layout className='raleway'>
							{!hideNavbarFooter && (
								<Row style={{ backgroundColor: "#fff" }}>
									<Col span={24}>
										{router.pathname !==
											"lp/tax-bankruptcy-attorney-florida" && <Navbar />}
									</Col>
								</Row>
							)}
							<Row
								style={
									{
										// marginTop: !hideNavbarFooter
										// 	? screenWidth.xl
										// 		? 185
										// 		: 110
										// 	: "",
									}
								}>
								<Col span={24}>
									<Component {...pageProps} />
								</Col>
							</Row>
							{!hideNavbarFooter && <CustomLiveChat />}
							{router.pathname === '/taxbankruptcy' && (
								<script type="application/ld+json">
									{JSON.stringify({
										"@context": "https://schema.org",
										"@type": "LegalService",
										"name": "Tax Bankruptcy Services",
										"url": "https://taxworkoutgroup.com/taxbankruptcy",
										"description":
											"Specialized legal services focusing on tax-motivated bankruptcy filings to eliminate or reduce substantial tax debts, including income, sales, payroll, employment, and excise taxes.",
										"provider": {
											"@type": "Organization",
											"name": "Tax Workout Group",
											"url": "https://taxworkoutgroup.com",
											"logo":
												"https://taxworkoutgroup.com/_next/image?url=%2FTWGUpdatedLogo.png&w=640&q=75",
											"contactPoint": {
												"@type": "ContactPoint",
												"telephone": "+1-561-571-2004",
												"contactType": "Customer Service",
												"areaServed": "US",
												"availableLanguage": ["English", "Spanish"],
											},
										},
										"serviceType": "Attorney",
										"areaServed": {
											"@type": "Country",
											"name": "United States",
										},
										"aggregateRating": {
											"@type": "AggregateRating",
											"ratingValue": "5",
											"reviewCount": "89",
										},
									})}
								</script>
							)}
						</Layout>
					</ConfigProvider>
				</AppWrapper>
			</div>
		</>
	);
};
export default appWithTranslation(MyApp);
